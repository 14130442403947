/* __next_internal_client_entry_do_not_use__ katexTypeset,useKatexTypeset,KatexProvider auto */ import { useEffect } from "react";
import { renderMathInElement } from "../utils/katextAutoRender";
export function katexTypeset(DOMelement) {
    renderMathInElement(DOMelement || document.body);
}
export function useKatexTypeset(DOMelement) {
    useEffect(()=>{
        if (DOMelement === null) return;
        if (window === undefined) return;
        katexTypeset(DOMelement);
        // @ts-ignore
        window.katexTypeset = katexTypeset;
    });
}
export function KatexProvider(param) {
    let { children, element = undefined } = param;
    useKatexTypeset(element);
    return children;
}
